





































































































































































import useRegistrationForm from "@/use/registrationForm";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import useImage from "@/use/image";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    lecturersItems: {
      type: Array,
      required: false,
      default: [],
    },
    abstractTopics: {
      type: Array,
      required: false,
      default: [],
    },
  },

  setup(props, { emit, root }) {
    const { fieldTypes } = useRegistrationForm({ root });
    const { uploadImage } = useImage({ root });

    const form = ref<any>(null);

    const tempImage = ref<null | File>(null);

    const model = ref<{ [key: string]: any }>(props.value);

    watch(model, (value) => emit("input", value), { deep: true });

    const state = reactive({
      open: false,
      valid: false,
      menu: false,
      loading: false,
      topicType: model.value.sessionTopic ? "abstract" : "custom",
    });

    const types = [
      {
        text: root.$tc("panel.event.program.itemEditor.simpleType"),
        value: "simple",
      },
      {
        text: root.$tc("panel.event.program.itemEditor.complexType"),
        value: "complex",
      },
    ];

    const hours = computed(() => {
      const hoursArray = [];

      for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 15) {
          hoursArray.push(
            `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`
          );
        }
      }
      return hoursArray;
    });

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    const open = () => (state.open = true);
    const close = () => (state.open = false);
    const toggle = () => (state.open = !state.open);

    const description = computed(() => {
      switch (model.value.type) {
        case "simple":
          return root.$tc("panel.event.program.itemEditor.returnSimple");
        case "complex":
          return root.$tc("panel.event.program.itemEditor.returnComplex");

        default:
          return null;
      }
    });

    const clearColor = () => {
      model.value.color = "#00000000";
    };

    const swatchStyle = computed(() => {
      return {
        backgroundColor: model.value.color ? model.value.color : "#00000000",
        cursor: "pointer",
        height: "28px",
        width: "28px",
        borderRadius: state.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        border: "2px solid rgba(0, 0, 0, 0.12)",
      };
    });

    watch(
      () => props.value.color.value,
      (newColor) => {
        props.value.color = newColor.hex;
      }
    );

    const save = async () => {
      if (typeof tempImage.value === "object" && tempImage.value !== null) {
        state.loading = true;
        await uploadImage(tempImage.value)
          .then(({ data }) => {
            model.value = Object.assign(model.value, { sponsorImage: data.id });
            tempImage.value = null;
          })
          .catch(console.log)
          .finally(() => (state.loading = false));
      }
    };

    const saveScheduleItem = async (item: any) => {
      if (typeof tempImage.value === "object" && tempImage.value !== null) {
        state.loading = true;
        await uploadImage(tempImage.value)
          .then(({ data }) => {
            item.image = data.id;
            tempImage.value = null;
          })
          .catch(console.log)
          .finally(() => (state.loading = false));
      }
    };

    const removeLecturer = (item: any) =>
      (model.value.lecturers = model.value.lecturers.filter(
        (i: any) => i.id !== item.id
      ));

    const filter = (item: any, q: string) =>
      item.lastName.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1;

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/eventProgram/`
    );

    watch(
      () => state.open,
      () => {
        if (state.open === false) {
          emit("save-program");
        }
      }
    );

    watch(
      () => state.topicType,
      () => {
        if (state.topicType === "abstract") {
          model.value.topic = model.value.sessionTopic?.id;
        }
      }
    );

    const checkSessionTopic = () => {
      if (state.topicType === "abstract") {
        model.value.topic = model.value.sessionTopic?.id;
      }
    };

    onMounted(checkSessionTopic);

    // watch(
    //   () => model.value,
    //   () => {
    //     if (state.topicType === "abstract") {
    //       model.value.topic = model.value.sessionTopic?.id;
    //     }
    //   },
    //   { deep: true }
    // );

    return {
      save,
      saveScheduleItem,
      tempImage,
      uuid,
      fieldTypes,
      form,
      model,
      state,
      types,
      hours,
      rules,
      open,
      close,
      toggle,
      description,
      swatchStyle,
      clearColor,
      removeLecturer,
      filter,
      filesUrl,
    };
  },
});
